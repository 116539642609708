.buttons-col {
    width: 150px;
}
.buttons-col button {
    margin-left: 2px;
}

.sliding-panel-container.active {
    box-sizing: border-box;
}
.sliding-panel-container {
    box-sizing: border-box;
}

.sliding-panel-container.active {
	box-sizing: border-box;
	z-index: 13000;
}

.MuiDialog-root {
    z-index: 13000!important;
}

/* MOBILE */
@media only screen and (max-width: 766px) {
}

@media only screen and (max-width: 600px) {
}

@media only screen and (min-width: 601px) and (max-width: 767px) {
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
}

@media only screen and (min-width: 993px) and (max-width: 1279px) {
}

@media only screen and (min-width: 1280px) {
}