:root {
	--color-primary: #9999ff;
	--color-secondary: #6C757D;
}

.w3-theme-l5 {color:#000 !important; background-color:#f9f9ff !important}
.w3-theme-l4 {color:#000 !important; background-color:#ebebff !important}
.w3-theme-l3 {color:#000 !important; background-color:#d6d6ff !important}
.w3-theme-l2 {color:#000 !important; background-color:#c2c2ff !important}
.w3-theme-l1 {color:#000 !important; background-color:#adadff !important}
.w3-theme-d1 {color:#fff !important; background-color:#7070ff !important}
.w3-theme-d2 {color:#fff !important; background-color:#4747ff !important}
.w3-theme-d3 {color:#fff !important; background-color:#1f1fff !important}
.w3-theme-d4 {color:#fff !important; background-color:#0000f5 !important}
.w3-theme-d5 {color:#fff !important; background-color:#0000cc !important}

.w3-theme-light {color:#000 !important; background-color:#f9f9ff !important}
.w3-theme-dark {color:#fff !important; background-color:#0000cc !important}
.w3-theme-action {color:#fff !important; background-color:#0000cc !important}

.w3-theme {color:#fff !important; background-color:#9999ff !important}
.w3-text-theme {color:#9999ff !important}
.w3-border-theme {border-color:#9999ff !important}

.w3-hover-theme:hover {color:#fff !important; background-color:#9999ff !important}
.w3-hover-text-theme:hover {color:#9999ff !important}
.w3-hover-border-theme:hover {border-color:#9999ff !important}

.w3-color-theme-l5 {color:#f9f9ff !important}
.w3-color-theme-l4 {color:#ebebff !important}
.w3-color-theme-l3 {color:#d6d6ff !important}
.w3-color-theme-l2 {color:#c2c2ff !important}
.w3-color-theme-l1 {color:#adadff !important}
.w3-text-theme {color:#9999ff !important}
.w3-color-theme-d1 {color:#7070ff !important}
.w3-color-theme-d2 {color:#4747ff !important}
.w3-color-theme-d3 {color:#1f1fff !important}
.w3-color-theme-d4 {color:#0000f5 !important}
.w3-color-theme-d5 {color:#0000cc !important}