body {
    margin: 0;
    background-color: #F4F6F8!important;
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

html {
    overflow-x: hidden;
    height: 100%;
}

.content-min-height {
    min-height: calc(100vh - 324px);
    padding-bottom: 60px;
}

.my-content {
    width: 100%;
    max-width: 1120px;
    margin: auto;
    padding-left: 16px;
    padding-right: 16px;
}

.cursor-pointer:hover {
    cursor: pointer !important;
}

.visibility-hidden {
    visibility: hidden;
}

.visibility-visible {
    visibility: visible;
}

.background-cover {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
}

.xhover-pointer:hover {
    cursor: pointer;
}

.xlabel {
    margin-bottom: 0px;
    color: gray;
    font-weight: bold;
    font-size: 14px;
}

.xbold {
    font-weight: bold;
}

.bs-bbox {
    box-sizing: border-box;
}

.full-size {
    width: 100%;
    height: 100vh;
}

.paginator-li {
    padding: 0px!important;
}

.paginator-li a {
    padding: 8px 16px;
    display: inline-block;
}

.paginator-container>ul {
    padding-inline-start: 0px!important;
}

.margin-2 {
    margin: 2px!important;
}

.tabla {}

.tabla thead {
    color: #7A8E98;
}

.tabla tbody td {
    vertical-align: middle!important;
}

.tabla tbody {
    color: #263238;
}

.tabla tfoot {
    color: #263238;
}

.thumb-container {
    height: 70px;
    width: 70px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: solid #dddddd 1px;
}
.thumb-container-large {
    height: 120px;
    width: 120px;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: solid #dddddd 1px;
}
/* Estilos del dropzone */
.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}
.thumbs-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;
}
.thumbs-container .thumb-box {
    display: inline-flex;
    border-radius: 2px;
    border: 1px solid #eaeaea;
    margin-bottom: 8px;
    margin-right: 8px;
    width: 100px;
    height: 100px;
    padding: 4px;
    box-sizing: border-box;
};
.thumbs-container .thumb-inner {
    display: flex;
    min-width: 0;
    overflow: hidden;
};
/* Fin estilos del dropzone */

.thumb-container-resp {
    width: 100%;
    padding-top: 100%;
    background-color: #F4F6F8;
    position: relative;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.xtag-gray {
    /* background: #eee; */
    background: #e0e0eb;
    border-radius: 3px 0 0 3px;
    /* color: #999; */
    color: #000;
    display: inline-block;
    height: 20px;
    line-height: 20px;
    padding: 0 20px 0 23px;
    position: relative;
    margin: 0 10px 10px 0;
    text-decoration: none;
    --webkit-transition: color 0.2s;
    font-size: 12px;
}

.xtag-gray::before {
    background: #fff;
    border-radius: 10px;
    box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
    content: '';
    height: 6px;
    left: 10px;
    position: absolute;
    width: 6px;
    top: 6px;
}

.xtag-gray::after {
    background: #fff;
    border-bottom: 11px solid transparent;
    /* border-left: 10px solid #eee; */
    border-left: 10px solid #e0e0eb;
    border-top: 9px solid transparent;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
}

.xtag-gray:hover {
    background-color: #e0e0eb;
    color: #fff;
}

.xtag-gray:hover::after {
    border-left-color: #e0e0eb;
}

tr.selected .xtag-gray:after {
	background: #f1f1f1;
}


/* MOBILE */

@media only screen and (max-width: 766px) {}

@media only screen and (max-width: 600px) {}

@media only screen and (min-width: 601px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) and (max-width: 992px) {}

@media only screen and (min-width: 993px) and (max-width: 1279px) {}

@media only screen and (min-width: 1280px) {}