.buttons-col {
    width: 150px;
}

.buttons-col button {
    margin-left: 2px;
}

.sliding-panel-container.active {
    box-sizing: border-box;
}

.sliding-panel-container {
    box-sizing: border-box;
}

.crop-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 80px;
}

.crop-controls {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 50%;
    transform: translateX(-50%);
    height: 80px;
    display: flex;
    align-items: center;
}

.crop-slider {
    padding: 22px 0px;
}

#crear-producto .rdw-option-wrapper img, #editar-producto .rdw-option-wrapper img {
    width: 20px!important;
}

#crear-producto .rdw-editor-main, #ver-producto .rdw-editor-main, #editar-producto .rdw-editor-main {
    border: solid #C4C4C4 1px;
    padding: 8px;
    border-radius: 4px;
}

/* MOBILE */

@media only screen and (max-width: 766px) {}

@media only screen and (max-width: 600px) {}

@media only screen and (min-width: 601px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) and (max-width: 992px) {}

@media only screen and (min-width: 993px) and (max-width: 1279px) {}

@media only screen and (min-width: 1280px) {}