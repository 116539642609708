.buttons-col {
	width: 150px;
}

.buttons-col button {
	margin-left: 2px;
}

.sliding-panel-container.active {
	box-sizing: border-box;
	z-index: 13000;
}

.sliding-panel-container {
	box-sizing: border-box;
}

.crop-container {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 80px;
}

.crop-controls {
	position: absolute;
	bottom: 0;
	left: 50%;
	width: 50%;
	transform: translateX(-50%);
	height: 80px;
	display: flex;
	align-items: center;
}

.crop-slider {
	padding: 22px 0px;
}

.tabla-pedidos td {
	vertical-align: middle;
}

.tabla-pedidos tr.via-admin {
	background-color: #f1f1cad8;
}

.tabla-pedidos tr.pedido-cancelado {
	background-color: #f5dbdb;
}

.tag {
	border-radius: 3px;
	display: inline-block;
	width: 220px;
	height: 26px;
	line-height: 26px;
	padding: 0 20px 1px;
	position: relative;
	text-decoration: none;
	transition: color .2s;
	-webkit-transition: color .2s;
	text-align: center;
	color: white;
}

.tag.online-tienda {
	background-color: #438bca;
}

.tag.online-envio {
	background-color: #b24598;
}

.tag.tienda {
	background-color: #f7ab1e;
}

.tag.envio {
	background-color: #339933;	
}

.tag.envio-interior {
	background-color: #035003;	
}

/* MOBILE */

@media only screen and (max-width: 766px) {}

@media only screen and (max-width: 600px) {}

@media only screen and (min-width: 601px) and (max-width: 767px) {}

@media only screen and (min-width: 768px) and (max-width: 992px) {}

@media only screen and (min-width: 993px) and (max-width: 1279px) {}

@media only screen and (min-width: 1280px) {}